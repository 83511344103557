import React from "react"
import LandingLayout from "../layouts/landing"
import RoundImageWithSubtitle from "../components/RoundImageWithSubtitle"
import TwoThirdsGrid from "../components/layout/TwoThirdsGrid"
import Text from "../components/text/Text"
import HeadlineWithSub from "../components/text/HeadlineWithSub"
import { strichpunkt } from "../data/akkordeon"
import FourImageGrid from "../components/layout/FourImageGrid"
import * as PropTypes from "prop-types"

function WrapperDiv(props) {
  return null
}

WrapperDiv.propTypes = { children: PropTypes.node }
const Home = () =>
  <LandingLayout akkordeon={strichpunkt} image="/assets/images/IMG_6978.jpg">
    <TwoThirdsGrid textRight>
      <div>
        <HeadlineWithSub>
          <h1 style={{ hyphens: 'none' }}>Klarheit gewinnen</h1>
        </HeadlineWithSub>
      </div>
      <div>
        <Text>
          <p>
            <b>Angebot für Menschen in herausfordernden beruflichen und privaten Situationen,
              persönlichen Krisen oder zur Standortbestimmung.</b>
          </p>
          <p>
            Schwerpunkte <br />
            Stress- und Burnoutprävention Erwerbs- und Familienleben <br/>
            Standortbestimmung und Neuausrichtung für Frauen ab 40 <br/>
            Partner- & Elternschaft
          </p>
          <p>
            Arbeitsort Raum Appenzell / Toggenburg / Stadt St. Gallen oder online...<br/>
            …in der Praxis, in einem geschützten Workspace oder auf einem Spaziergang in der Natur
          </p>
          <p>
            Für Terminvereinbarungen kontaktieren sie mich bitte per <a href="tel:+41 79 844 04 07">Telefon</a> oder <a
            href="mailto:info@strich.sg">eMail</a>.
          </p>
        </Text>
      </div>
    </TwoThirdsGrid>
    <FourImageGrid>
      <RoundImageWithSubtitle text="Mal- & Gestaltungtherapie"
                              subtext="Sprache plus, Gestaltung, Handlungsraum, Betrachtung"
                              link="/mal-und-gestaltungstherapie" image="/assets/images/IMG_7007_smaller.jpeg" />
      <RoundImageWithSubtitle text="Coaching" subtext="Vertrauen, Handlungsraum, Klarheit, Entwicklung" link="/coaching"
                              image="/assets/images/IMG_7047.PNG" />
      <RoundImageWithSubtitle text="Natur und ihre Bedeutung" subtext="im Kontext Coaching & Therapie" link="/natur-und-ihre-bedeutung"
                              image="/assets/images/IMG_6985.jpg" />
      <RoundImageWithSubtitle text="Weiterbildung" subtext="Natur, Wahrnehmung, Gestaltung, Prozess" link="/weiterbildung"
                              image="/assets/images/IMG_7210.jpeg" />
    </FourImageGrid>
  </LandingLayout>

export default Home