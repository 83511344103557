import React from "react"
import {Link} from "gatsby"
import {breakpoints, spacers, heights} from "../../styles/variables"
import styled from "styled-components"

const NavListNav = styled.nav`
    //display: none;

    @media screen AND (max-width: 1023px) {
        position: fixed;
        bottom: -100%;
        left: 0;
        right: 0;
        top: 100%;
        background: black;
        height: calc(100vh - ${heights.navDefault.s}px);
        width: 100%;
        z-index: 0;
        transition: all 300ms;
        padding: 40px ${spacers.s}px 0;
        overflow-y: scroll;
        
        
        .signetDiv {
            background-image: url('/assets/gestaltungselemente/Strichpunkt-Signet-Negativ.svg');
            background-size: 100%;
            background-repeat: no-repeat;
            position: absolute;
            width: 27px;
            height: 68px;
            right: 21px;
            bottom: 31px;
        }

        &.isOpen {
            bottom: 0;
            top: ${heights.navLanding.s}px;
            display: flex;
            flex-direction: column;
            
            a {
                color: white;
            }
        }

        > div {
            display: flex;
            flex-direction: column;
        }
    }
       
    @media screen AND (min-width: ${breakpoints.m}px) { 
        display: grid;
        grid-template-columns: 202px 105px 64px;
        grid-column-gap: 100px;
        margin: 28px 0 38px;

        > div {
            display: flex;
            flex-direction: column;

            a {
                color: #000;
            } 
        }

        ..signetDiv {
            display: none;
        }
    }
    @media screen AND (min-width: ${breakpoints.l}px) {     
        margin: 35px 0 44px;
        grid-column-gap: 128px;
        grid-template-columns: 270px 140px 84px;
    }
`

const NavList = ({isOpen, setIsOpen}) => 
    <NavListNav className={isOpen ? "isOpen landing" : "landing"}>
        <div>
            <Link to="/mal-und-gestaltungstherapie">Mal- & Gestaltungstherapie</Link>
            <Link to="/coaching">Coaching</Link>
            <Link to="/natur-und-ihre-bedeutung">Natur und ihre Bedeutung</Link>
        </div>
        <div>
            <Link to="/weiterbildung">Weiterbildung</Link>
            <Link to="/preise">Preise</Link>
            <Link to="/garten">Garten</Link>
        </div>
        <div>
            <Link to="/portrait">Portrait</Link>
            <Link to="/literatur">Literatur</Link>
            <Link to="/kontakt">Kontakt</Link>
        </div>
        <div className="signetDiv"></div>
    </NavListNav>

export default NavList