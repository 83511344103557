import React, {useState} from "react"
import styled from "styled-components"
import { breakpoints, heights } from "../../styles/variables"

import Wrapper from "../layout/Wrapper"
import NavTitleLanding from "./NavTitleLanding"
import NavToggle from "./NavToggle"
import NavListLanding from "./NavListLanding"

const HeaderDiv = styled.div`
    height: ${heights.navLanding.s}px;
    width: 100%;
    background: white;
    position: fixed;
    box-shadow: #00000029 0px 3px 6px;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;

    > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 100%;
    }

    @media screen AND (min-width: ${breakpoints.m}px) {
        height: ${heights.navLanding.m}px;

        > div > div:nth-of-type(2) {
            display: none;
        }
    }

    @media screen AND (min-width: ${breakpoints.l}px) {
        height: ${heights.navLanding.l}px;
    }
`

const NavLanding = () => {
    const [isOpen, setIsOpen] = useState(false)
    return(<HeaderDiv>
    <Wrapper xl>
        <NavTitleLanding />
        <NavToggle isOpen={isOpen} setIsOpen={setIsOpen} />
        <NavListLanding isOpen={isOpen} setIsOpen={setIsOpen} />
    </Wrapper>
</HeaderDiv>)
}


export default NavLanding