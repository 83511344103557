import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { breakpoints } from "../../styles/variables"

const NavTitleLandingDiv = styled.div`

    a {
        display: flex;
        flex-direction: row;
        height: 100%;
        &:hover {
            text-decoration: none;
        }
    }

    h2 {
        margin: 15px 0 11px 0;
        font: 28px / 32px "Karla ExtraBold", sans-serif;
        color: black;
    }

    img {
        display: none;
    }

    @media screen AND (min-width: ${breakpoints.m}px) {
        h2 {
            font: 47px / 55px "Karla ExtraBold", sans-serif;
            margin: 44px 22px 49px 0;
        }

        img{
            display: block;
            width: 36px;
            height: 91px;
            margin: 28px 0 29px
        } 
    }

    @media screen AND (min-width: ${breakpoints.l}px) {
        h2 {
            font: 60px / 70px "Karla ExtraBold", sans-serif;
            margin: 54px 27px 62px 0;
        }
        img {
            width: 46px;
            height: 116px;
            margin: 35px 0;
        }
    }
`

const NavTitleLanding = () => <NavTitleLandingDiv>
    <Link to="/"><h2>strichpunkt</h2>
    <img src="/assets/gestaltungselemente/Strichpunkt-Signet-Positiv.svg" alt="logo" /></Link>
</NavTitleLandingDiv>

export default NavTitleLanding