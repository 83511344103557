import React from "react"
import styled from "styled-components"
import Helmet from "react-helmet"

import "../styles/normalize.css"
import "../styles/fonts.css"
import "../styles/base.css"
import "../styles/font-styles.scss"

import HeaderImage from "../components/HeaderImage"
import Wrapper from "../components/layout/Wrapper"
import NavLanding from "../components/navigation/NavLanding"
import Footer from "../components/footer/Footer"

import { heights, breakpoints } from "../styles/variables"
import Akkordeon from "../components/Akkordeon"

const LayoutDiv = styled.div`
    main {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        min-height: 100vh;
        
        margin-top: ${heights.navLanding.s}px;

        @media screen AND (min-width: ${breakpoints.m}px) {
            margin-top: ${heights.navLanding.m}px;
        }

        @media screen AND (min-width: ${breakpoints.l}px) {
            margin-top: ${heights.navLanding.l}px;
        }
    }
`

const LandingLayout = ({children, image, akkordeon}) => {
    return (
        <LayoutDiv>
            <Helmet>
                <link rel="apple-touch-icon" sizes="180x180" href="/assets/favicon/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/assets/favicon/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/assets/favicon/favicon-16x16.png" />
                <link rel="manifest" href="/assets/favicon/site.webmanifest" />
                <link rel="mask-icon" href="/assets/favicon/safari-pinned-tab.svg" color="#5bbad5" />
                <link rel="shortcut icon" href="/assets/favicon/favicon.ico" />
                <meta name="msapplication-TileColor" content="#00aba9" />
                <meta name="msapplication-config" content="/assets/favicon/browserconfig.xml" />
                <meta name="theme-color" content="#ffffff"></meta>
                <meta name="description" content="Kleines, aber feines Angebot in St. Gallen für Kinder, Jugendliche und Erwachsene in schwierigen Lebenslagen, Krisensituationen oder mit Belastungen am Arbeitsplatz bzw. in der Familie oder Schule. Wir arbeiten im Atelier, in der Natur und im Garten.
                Mal- und Gestaltungstherapie ist für Kinder, Jugendliche und erwachsene Personen geeignet und findet im Atelier statt. Auf Wunsch und nach Absprache kann diese in Kombination mit Naturerfahrungen oder im Garten stattfinden.
                NaturUndIhreBedeutung ist eine aussergerichtliche Form der Konfliktbearbeitung. Sie baut auf grundlegenden Faktoren wie Freiwilligkeit, Transparenz, Lösungsoffenheit und Vertraulichkeit auf. Das Mediationsverfahren ist ein durch Phasen strukturierter Prozess, der von einer Mediatorin begleitet wird.
                Zusätzlich biete ich Coaching von Eltern im Bereich spezielle Bedürfnisse im Zusammenhang mit Beeinträchtigung oder Behinderung in (heil-)pädagogischen Fragen und Coaching bzw. Coaching von Einzelpersonen in herausfordernden Situationen, persönlichen Krisen oder zur Standortbestimmung.
                Saisonal finden Weiterbildung und Workshops zu Themen wie Gestaltungstechniken mit Naturmaterialien bzw. Pflanzen oder Regeneration bei Stress oder sonstigen Belastungen in der Natur statt."></meta>
                <title>strichpunkt</title>
            </Helmet>
            <NavLanding />
            {/* Header IMG */}
            <main>
                <HeaderImage image={image} />
                <Wrapper>
                {children}
            </Wrapper>
            </main>
            <Akkordeon data={akkordeon} />
            <Footer />
        </LayoutDiv>
    )
}

export default LandingLayout